.App {
  text-align: center;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  box-shadow: none !important;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  border: inherit;
  background-color: inherit;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

e-checkbox-wrapper .e-checkbox:focus + .e-frame,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame {
  box-shadow: none !important;
}
.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
  box-shadow: none !important;
}
